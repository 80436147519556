
<template>
    <div class="main-part">
        <h3 ref="top" class="column-3">Privacy Policy</h3>
        <div class="column-9">

            Last updated: June 5th, 2019<br>
            Welcome to our website, bingxu.brantte.com.<br>
            For us at Bing Xu, your privacy and the security of your personal data are very important, for this reason we collect and manage your personal data with care and take specific measures designed to keep your personal data secure. This privacy policy (“Privacy Policy” or “Privacy Notice”) contains information on the processing by Bing Xu of your personal data in relation to your browsing and use of the website bingxu.com and all corresponding webpages thereof, applications, features, functionality, information and services made available by us on or through any of the foregoing (collectively, the “Website”). We also ask you to read the
            General Conditions of Use
            and the Conditions relating to registration, which contain additional information and terms and conditions governing your use of the Website. Some aspects of the Website may be subject to additional specific legal terms and conditions, in which case, such terms and conditions will be made available to you when you use or access such services.<br>
            <ol>
                <li>
                    <h4>GENERAL INFORMATION</h4>
                    <p>WHO IS THE DATA CONTROLLER?<br>
                        Bing Xu, based at 609 Greenwich Street New York, NY 10014 United States (“Bing Xu”, “we”, “us”, “our”, etc.) is the Data Controller, namely the party which decides how and why to process your personal data.<br>
                        You can always contact Bing Xu with any privacy-related questions or requests by writing to the above address or writing to privacy@bingxu.com.<br>
                        Bing Xu has designated a Controller's Representative in the European Union. If you wish, you can contact the Controller’s Representative by writing to the address 8 Rue Francs Bourgeois, Paris, France 75003 or by email to creu@bingxu.com.
                    </p>
                </li>
                <li>
                    <h4> WHAT PERSONAL DATA DO WE COLLECT?</h4>
                    <p>The categories of personal data that Bing Xu collects and processes when you browse or shop on the Website, are as follows:</p>
                    <ol class="two-level">
                        <li>We collect the personal data necessary to complete and execute your purchase on the Website such as given name and surname, email address, shipping address, billing address, telephone and payment details.</li>
                        <li>We collect your email address when you sign up to receive newsletters or other marketing or promotional emails regarding Bing Xu.</li>
                        <li>We process the personal data you provide us with when you contact our Customer Service  to provide you with the assistance requested.</li>
                        <li>With your consent, we collect and use your personal data for marketing communications.</li>
                        <li>For the registration of your account, we collect your given name and surname, your email address, your password and your email preferences. If you are a registered user, we collect information about your access to the reserved area of the Website, including information on the date and time of such access. With your express consent, we may analyze your personal data to gather information regarding your interests and preferences with respect to our products and services, in order to present proposals and offers in line with your tastes.</li>
                        <li>We collect information about your browsing on the Website, such as the pages you visit and how you interact with them.</li>
                        <li>With your consent, we may collect certain information, such as IP address, type of browser, type of device, unique advertising identifiers and type of operating system used, through cookies and/or similar technologies (as described in Section 5 below).</li>
                        <li>We may receive information about you from third parties, which may include social media services (e.g., Instagram) of which you are a registered user. Bing Xu does not knowingly process minors’ personal data. If you access the Website and use services of Bing Xu, you declare that you are above the age of majority in your jurisdiction.</li>
                    </ol>
                    <p></p>
                </li>
                <li>
                    <h4>HOW DO WE USE THE PERSONAL DATA COLLECTED?</h4>
                    <p>Bing Xu collects and processes your personal data for the following purposes: </p>
                    <ol class="two-level">
                        <li>Purchases. When you purchase a product on the Website, we ask you for the personal data necessary to finalize and confirm such purchase, such as payment and related anti-fraud checks, billing, shipping of the product and processing the return of such product, as applicable</li>
                        <li>Registration on the Website. We use certain personal data you provide us as part of the registration process to provide you with a registered account on the Website and the services offered to registered users</li>
                        <li>Provision of services offered on the Website. Bing Xu collects, in relation to each service and its characteristics, the personal data necessary for the provision of the applicable service requested by you</li>
                        <li>Customer Service requests. We collect and use the personal data you provide us when you send a request to our Customer Service, in order to process and respond to your request</li>
                        <li>Analysis and statistical surveys. We may use certain information about your use of the Website, mode of browsing and use of the related services, to conduct analyses and statistical surveys in order to improve our offering and our services</li>
                        <li>Transaction Communications. We use your personal data to send you to the email you provided us communications regarding orders you have placed on the Website (e.g., order confirmation, status updates, shipping and delivery notifications, etc.)</li>
                        <li>Marketing Communications. With your express consent, we may use the contact details you have provided to us, for purposes of sending you marketing communications on our products and services, in order to update you on news, new arrivals, exclusive products, and on our offers and promotions. In addition, with your consent, we may use your contact details to conduct market research and surveys for customer satisfaction in order to improve our services and our relationship with our users</li>
                        <li>Personalized Services. With your consent, Bing Xu may personalize your experience on the Website, which may include showing you previews and offers in line with your interests, and Bing Xu may send you personalized marketing communications and personalized promotional offers. The personalization will be carried out by analyzing your previous purchases and other information described in the paragraph ‘What personal data do we collect?’. For more information on the personal data used for purposes of such personalization and on the related activities, please consult the paragraph ‘My Account’</li>
                    </ol>
                    If you authorize the activities referred to in points g) and h) and you subsequently wish to stop receiving further communications from Bing Xu or you would like to limit how to be contacted, you can opt-out of these communications at any time by clicking on the dedicated link “Unsubscribe” at the bottom of each communication, or accessing your account, or contacting Bing Xu by contacting our Customer Service or by writing to the above address. You may receive further communications from us even after submitting your request, as some further communications may have already been planned, and our systems may take between 24 and 48 hours to process your request.<br>
                    Bing Xu does not currently sell or disclose for business purposes any personal data collected by it in connection with use of the Website, and has not done so during the previous twelve month period.
                    <p></p>
                </li>
                <li>
                    <h4>MY ACCOUNT</h4>
                    <p> If you create an account on the Website, you may access and edit the following information via your account:</p>
                    <ul>
                        <li>Personal Details: check and edit your personal details, email address and password</li>
                        <li>Orders: enter your Order Number to see the list of products you have ordered and information on their delivery status</li>
                        <li>Card wallet: store your credit card details</li>
                        <li>Address Book: store your address details to complete the order process quickly.</li>
                        <li>Wish List: create your ideal wardrobe, a personal collection of your favorite items. Save up to 50 of your most wanted items to follow their availability and add them directly to your Shopping Bag.</li>
                    </ul> With your express consent, we may use the contact details you have provided for marketing communications regarding our products and services, in order to update you on news, new arrivals, exclusive products, and on our offers and promotions, and for market research and surveys to determine your level of satisfaction and improve our services.<br>
                    With your express consent, Bing Xu may personalize, in line with your interests, your experience and the content of commercial communications and the offers you will receive when you browse the Website as a registered user. This allows you to streamline the search for products and services of greatest interest to you and, at the same time, allows us to improve our offers to you. Such personalization is made possible by the analysis of your personal data in our possession, described in the paragraph ‘What personal data do we collect?’. In particular, information on purchases you have made in the past and those relating to the sections of the Website you visit most often or the services you use most often help us to understand which products and services are more interesting to you.<br>
                    You are responsible for ensuring that the personal data you provide to Bing Xu is accurate and up to date. To ensure that the information in our possession is correct and allows us to perform properly the activities described above, we invite you to access the section ‘My Profile’ of ‘My Account’ and, if you feel it is necessary, to update it.
                    <p></p>
                </li>
                <li>
                    <h4>COOKIES</h4>
                    <p> The Website uses automatic systems of data collection, such as cookies and similar tracking devices. A cookie is a small data file that is transmitted to the hard drive of an Internet user’s computer, tablet, phone or other device. Cookies allow us to link an Internet user to personal information provided by such user through the Website. Cookies are disseminated by our or our service providers’ servers. Only Bing Xu (and its service providers) processes the information collected by the cookies on the Website, in order to optimize its services and the Website for the needs and preferences of its users.<br>
                        We disseminate cookies in connection with functions such as browsing the Website, purchasing products and supplying "My Account" services.<br> As you may know, each Internet browser allows the deletion of cookies after each session. Your Internet browser contains instructions on how to carry out these procedures of deletion. Please access the appropriate instructions section on your Internet browser if you wish to delete cookies.<br>
                        Your acceptance of the use of cookies is necessary to take advantage of many features and services offered by the Website, including the purchase of products. If you set your browser to block or delete cookies, you may not be able to access certain features and services offered by the Website (for example, your computer may not be able to display the image of the product you are in the process of purchasing).<br>
                        Our website uses various types of first party cookies (organized and managed by Bing Xu), and third party cookies (organized and managed by third parties based on their own individual privacy policies) and similar technologies, each of which has a specific function. Below is a table explaining them.<br>
                    </p>
                    <table cellpadding="0" cellspacing="0" class="cookiePolicy" id="cookiePolicy">
                        <tbody>
                        <tr>
                            <th style="width: 25%"><strong>TYPE OF COOKIE</strong></th>
                            <th><strong>FUNCTION</strong></th>
                        </tr>
                        <tr>
                            <th>Navigation cookie</th>
                            <td>From the first access these cookies allow the website to function correctly and allow you to view content on your device by recognising the language and market of the country/region from which you’ve chosen to connect. If you are a registered user, they will allow you to be recognised and to access the services offered from the dedicated areas. Navigation cookies are technical cookies and are needed for the functioning of the website.</td>
                        </tr>
                        <tr>
                            <th>Functional cookies</th>
                            <td>These cookies allow, based on your express request, for you to be recognised when you subsequently access the website, so that you do not have to enter your information each time (for example: "Remember me"). If you have added items into your Shopping Bag and closed the session without completing the purchase and without eliminating those items, these cookies allow you to continue the shopping experience the next time you access the website (within a limited period), finding the same articles that were selected. Functional cookies are not essential to the functioning of the website, but rather improve navigation quality and experience.</td>
                        </tr>
                        <tr>
                            <th>Analytical cookies</th>
                            <td>These first party cookies are the property of Bing Xu and are used to collect data in an anonymous and aggregate way. These cookies are used to prepare statistical analyses on the navigation methods of our website’s users. Bing Xu treats the results of these analyses anonymously and exclusively for statistical purposes only.</td>
                        </tr>
                        <tr>
                            <th>Our own and third party marketing and profiling cookies</th>
                            <td>These cookies are aimed at creating user-related profiles to send commercial messages that meet the preferences shown during the visit, or to improve your navigation experience: while you navigate on our website, these cookies are useful for showing you products of interest to you or which are similar to those you have viewed. Third party cookies are those that have been sent by our trusted third party companies. These cookies allow you to be provided with our commercial offering on other affiliated websites (retargeting). With third party cookies we do not have control of the information provided by the cookie and we do not have access to this data. This information is entirely controlled by third party cookies as described in the respective policy. To find out more about the aforementioned third parties cookie, you can inform yourself on their respective polices and knowingly manage your consent or refusal, for this we invite you to visit http://youronlinechoices.com. </td>
                        </tr>
                        <tr>
                            <th>Social Network Cookies</th>
                            <td>These cookies are necessary to allow your social account to interact with our website. For example, they are used to express your appreciation and to share it with your social networking friends. The social network cookies are not needed for navigation. For more information on the policies regarding the use of social network cookies, it is possible to consult the respective privacy and cookies policies:
                                <ul>
                                    <li>Facebook: http://www.facebook.com/about/privacy/</li>
                                    <li>Google+: http://google.com/intl/gb/policies/privacy/</li>
                                    <li>Twitter: http://twitter.com/privacy</li>
                                    <li>Instagram: https://instagram.com/legal/cookies/</li>
                                    <li>YouTube: https://www.youtube.com/static?template=privacy_guidelines</li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p></p>
                    <p>You can easily manage your consent or refusal by accessing the section of your browser through the following links:</p>
                    <ul>
                        <li>IE: https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</li>
                        <li>Safari: https://support.apple.com/kb/PH17191?locale=en_US</li>
                        <li>Chrome: https://support.google.com/chrome/answer/95647?hl=en&amp;topic=14666&amp;ctx=topic</li>
                        <li>Firefox: https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</li>
                    </ul>
                    How We Respond to Do Not Track Signals: At this time the Website does not recognize automated browser signals regarding tracking mechanisms, which may include "do not track" instructions.
                    <p></p>
                </li>
                <li>
                    <h4>LEGAL BASIS OF THE PROCESSING</h4>
                    <p>If you are a user located within the European Economic Area, we only process your personal data if one or more of the following legal basis applies:</p>
                    <ol class="two-level">
                        <li>for the finalization and execution of a contract to which you are a party.<br>
                            When we process your data on this basis we take care to use only the minimum information necessary to execute the contract. This basis legitimizes the processing of personal data that takes place in the following activities:
                            <ul>
                                <li>finalization and execution of a contract for the purchase of goods offered on the Website;</li>
                                <li>registration on the Website and use of the services reserved for registered users;</li>
                                <li>supply of services offered on the Website;</li>
                                <li>management of your requests by our Customer Service.</li>
                            </ul>
                            The provision of your personal data for these activities is a contractual obligation. You are free to communicate your data or not, but in the absence of the requested data it will not be possible to finalize or execute the contract and your requests. This means that you will not be able to purchase the products and to use the services of Bing Xu and that Bing Xu will not be able to process your requests.</li>
                        <p></p>
                        <li>to follow up on a legal obligation.<br>
                            In case of finalization of a contract for the purchase of goods on the Website, the processing of user data will take place to give effect to the legal obligations to which Bing Xu is bound. You are free to decide whether to finalize a contract and whether or not to disclose your data, but if you finalize it your data will be necessary and will be processed to give effect to the aforementioned legal obligations to which Bing Xu is bound.</li>
                        <p></p>
                        <li>Due to our legitimate interest.<br>
                            <p>Certain personal data will be processed to carry out anti-fraud activities relating to your purchase of products on the Website: we have a legitimate interest in carrying out this activity to prevent and prosecute any fraudulent activity. For internal administrative purposes, personal data may be processed by Bing Xu’s parents, subsidiaries or other affiliated companies or our authorized service providers. </p>
                        </li>
                        <p></p>
                        <li>Based on your consent.<br>
                            <p>We will carry out the following processing only if you have given us your express consent:</p>
                            <ul>
                                <li>marketing activities and market research and surveys;</li>
                                <li>analysis of your browsing data and your shopping habits when you are signed in to your account on the Website, in order to personalize your experience on the Website.</li>
                            </ul>
                        </li>
                    </ol>
                    <p></p>
                    <p>The provision of your personal data for these activities is optional.</p>
                </li>
                <li>
                    <h4>WHO WILL PROCESS YOUR DATA?</h4>
                    <p>Your personal data will be processed by Bing Xu and may also be transferred to and processed by Bing Xu’s parents, subsidiaries or other affiliated companies.<br>
                        Your personal data may also be transferred to third parties for the purpose of (1) assisting with facilitating transactions on or through the Website, (2) sending you advertisements for our products and services, (3) providing information technology and customer service assistance, and (4) providing, offering, supporting, administering, maintaining and enabling the provision of the Website and our services. These parties have been carefully selected by us and are required to comply with all applicable data protection regulations. These parties have been appointed as data processors and carry out their activities according to the instructions given by Bing Xu and under its control. <br>
                        These third-parties belong to the following categories: site operator, banking operators, internet providers, IT companies, couriers, marketing companies, and companies specialized in market research and analysis.<br>
                        Your data may be disclosed to law enforcement and judicial and administrative authorities, in accordance with applicable law, for the investigation and prosecution of crimes, the prevention and protection from threats to public security, to allow Bing Xu to ascertain, exercise or defend a right in court, as well as for other reasons related to the protection of the rights and freedoms of others.<br>
                        Bing Xu may also use a variety of third parties, including advertising networks, data exchanges, traffic measurement service providers and marketing analytics service providers to, for example, serve advertisements on the Website, populate user-specific advertisements and/or measure and analyze advertising effectiveness and/or traffic on or in connection with the Website (“Third Party Advertising Providers”) through cookies and/or similar technologies (as described in Section 5). Such Third Party Advertising Providers may enable us to display advertisements based on your use of the Website and other websites, services and platforms you have used or visited.
                    </p>
                </li>
                <li>
                    <h4>EXTRA-EU TRANSFER</h4>
                    <p>If you are located in the EU, please note that Bing Xu and some of the third parties listed in the paragraph ‘Who will process your data?’ may be established in countries/regions outside the European Union. If you are located in the EU, your personal data will only be transferred outside the EU (a) to countries/regions that offer an adequate level of data protection, as established by specific decisions of the European Commission (http://www.garanteprivacy.it/home/provvedimenti-normativa/normativa/normativa community-and-intentional/transfer-of-data-to-third-countries#1’), or (b) pursuant to specific agreements containing safeguard clauses and appropriate guarantees for the protection of your personal data, known as “standard contractual clauses”, approved by the European Commission, or (c) if the data transfer is necessary for the finalization and execution of a contract between you and Bing Xu (for the purchase of goods offered on our Website, for registration on the Website or use of services on the Website) or for the management of your requests.</p>
                </li>
                <li>
                    <h4>HOW LONG DO WE KEEP THE DATA?</h4>
                    <p>We keep your personal data for a limited period of time, which differs depending on the type of activity that involves the processing of your personal data. After this period, your data will be permanently erased or otherwise irreversibly rendered anonymous.<br>
                        Your personal data are stored in compliance with the terms and criteria specified below:
                    </p>
                    <ol class="two-level">
                        <li>data collected to conclude and execute contracts for the purchase of goods on the Website: until the administrative and accounting formalities are finalized. The billing data will be kept for ten years from the billing date;</li>
                        <li>data of the registered user: the data will be kept until you request cancellation of your account;</li>
                        <li>payment details: up to the confirmation of the payment and to the finalization of the relative administrative and accounting formalities;</li>
                        <li>data collected in the context of the use of services offered on the Website: this data is kept until the termination of the user’s account or the user’s request to terminate such service;</li>
                        <li>data related to requests by users to our Customer Service : the data used to assist you will be kept until we determine that your request has been satisfied;</li>
                        <li>data used for marketing activities for users who purchase product on the Website: these data are kept until the termination of the service or until the user opts out of receiving marketing communications; </li>
                        <li>data provided for marketing activities and market research and satisfaction surveys: up to the request by the user to interrupt the activity and in any case within two years from the last interaction of any kind by the user with Bing Xu;</li>
                        <li>data used to customize the Website and to show you customized sales offers: until you request that we stop providing such customized version of the Website and customized offers, and in any case within three years from the last interaction of any kind by the user with the Website.</li>
                    </ol>
                    In any case, for technical reasons, the termination of the processing and the subsequent definitive deletion or irreversible anonymization of the related personal data shall be effective within thirty days from the terms indicated above.
                    <p></p>
                </li>
                <li>
                    <h4>YOUR RIGHTS</h4>
                    <p>At any time you may exercise your rights with reference to the specific processing of your personal data by Bing Xu. Please find below a general description of these rights and how to exercise them.</p>
                    <ol class="two-level">
                        <li>Access your personal data and rectify it: you have the right to access your personal data and to request that they are correct, modified or supplemented with other information. You may contact us to request a copy of your personal data in our possession.</li>
                        <li>Revoke your consent: you can revoke at any time the consent you may have given for the processing of your personal data for marketing purposes (i.e., for purposes of sending sales and promotional communications and conducting market research and surveys to determine the level of satisfaction and improve our services). Once we have received your request, we will promptly cease the processing of your personal data for marketing purposes.</li>
                        <li>Object to the processing of your data: you have the right to object to the processing of your personal data, under certain conditions.</li>
                        <li>Erasure of your data: you have the right to request the erasure of your personal data, under certain conditions.</li>
                        <li>Restriction of the processing of your personal data: have the right to request that we restrict the processing of your personal data in the following circumstances: when you contest the accuracy of your personal data, when the processing is unlawful but you oppose the erasure of your data, when the processing of your data is no longer needed but you need them for establishment, exercise or defense of legal claims and when you object to processing pending the verification of the reasons of your request.</li>
                        <li>Receiving your data and transferring it to a party other than Bing Xu (right to data portability). You can ask to receive your data that we process based on your consent or on the basis of a contract with you in a standard format.<br>
                            You may request that we, where technically possible, transfer your data directly to a third party indicated by you.<br>
                            You can exercise the rights described above by accessing your account, or writing to privacy@bingxu.com or writing to the address of the data controller.<br>
                            In order to ensure that the data of our users are not subject to breaches or unlawful use by third parties, before accepting your request to exercise one of the rights indicated, we will ask you to provide certain information so that we can verify your identity.
                        </li>
                    </ol>
                    <p></p>
                </li>
                <li>
                    <h4>SECURITY MEASURES</h4>
                    <p>We protect your personal data with specific technical and organizational security measures, aimed to prevent your personal data from being used unlawfully or fraudulently.<br>
                        In particular, we use security measures which are designed to ensure: the pseudonymization or encryption of personal data; confidentiality, integrity, availability of your personal data and the resilience of processing systems and services; the ability to restore personal data in case of data breach. Furthermore, Bing Xu undertakes to regularly test, assess and evaluate the effectiveness of such technical and organizational measures.
                    </p>
                </li>
                <li>
                    <h4>COMPLAINTS</h4>
                    <p>If you are located in the EU and believe that the processing of your personal data has been carried out unlawfully, you can lodge a complaint with one of the competent supervisory authorities.</p>
                </li>
                <li>
                    <h4>CHANGES TO THIS NOTICE</h4>
                    <p>From time to time, we may modify this Privacy Notice to reflect industry initiatives, third party requirements or changes in the law, technology, our information collection and use practices or the features and functionality of the Website or our services, and such modifications shall be effective upon posting. When we change this Privacy Notice in a material way, a notice will be posted on the Website along with the updated Privacy Notice. Your continued use of the Website after we post a revised Privacy Notice (and, where appropriate, notify you of same) signifies your acceptance of the revised Privacy Notice. It is therefore important that you review this Privacy Notice regularly to ensure you are updated as to any changes. We may continue to process your information based on our legitimate interests or in order to fulfill our obligations under a contract and/or may also seek your affirmative consent to the terms of the updated Privacy Notice. For example, we may ask for your consent before implementing new uses of the personal data that we’ve already collected from you based on such consent, if such new use was not addressed by the privacy policy under which such information was collected or if our legal basis for processing such information changes.</p>
                </li>
                <li>
                    <h4>LEGISLATIVE REFERENCES</h4>
                    <p>The processing of your personal data is carried out by Bing Xu in full compliance with, as applicable, Regulation (EU) 2016/679, General Data Protection Regulation and any other applicable data protection law.</p>
                </li>
            </ol>

            <div>
                <div class="backToTop" data-ytos-ctrl="ui.backToTop" data-ytos-opt="{&quot;threshold&quot;:400}" data-ytos-ver="1.0.4">                    <span class="icon"></span>
                    <span class="text" @click="backToTop">Back to top</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "privacyPolicy",
    data(){
        return{

        }
    },
    methods: {
        backToTop(){
            document.documentElement.scrollTop = document.body.scrollTop = 0;
            // document.getElementsByTagName('h3')[0].scrollIntoView({
            //     behavior: "smooth",
            //     block: "center",
            //     inline: "center"
            // });
        }
    }
}
</script>

<style scoped lang="scss">

.main-part {
    padding: 5rem 25rem;
    h1, h2, h3, h4, h5, h6 {
        display: inline-block;
        font-size: 1em;
        font-weight: 400;
        line-height: 1.1875rem;
        margin-top: 4rem;
    }
    .column-9 {
        margin-top: 2rem;
        line-height: 1.1875rem;
        letter-spacing: .01562rem;
    }
}

.main-part ol {
    counter-reset: item;
    text-indent: 0;
    padding: 0;

}
.main-part ul {
    padding: 0;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
    word-break: break-word;
    box-sizing: border-box;
}
li:after, :before {
    text-decoration: inherit;
    vertical-align: inherit;
}
.main-part ol>li:before {
    content: counters(item,".") ".";
    counter-increment: item;
}

.print-link {
    margin-top: 4rem;
    display: inline-block;
}
.backToTop {
    cursor: pointer;
    display: inline-block;
    margin-top: 4rem;
    .text {
        text-decoration: none;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        padding-bottom: .0625rem;
        border-bottom-color: transparent;
    }
}

table {
    margin: 2rem 0;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        td {
            padding-bottom: 2rem;
            word-break: break-word;
        }
    }
    b, strong, th {
        font-weight: 400;
    }
}

p{
    margin: 1rem 0;
}

.print-link .text {
    text-decoration: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding-bottom: .0625rem;
    border-bottom-color: transparent;
}
.backToTop .text {
    text-decoration: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
}
.print-link, .backToTop{
    cursor: pointer;
    appearance: none;
    background-color: transparent;
    border: 0 none;
    border-radius: 0;
    letter-spacing: inherit;
    padding: 0;
    color: inherit;
    box-sizing: border-box;
}
.backToTop:hover {
    box-sizing: border-box;
    border-bottom:1px solid #212121;
}

@media (min-width: 1280px) {
    .main-part {
        min-width: 40.625rem;
    }
    .two-level li {
        //display: flex;
        margin-left: 2.1875rem;
        margin-bottom: 1rem;
    }
}
@media (min-width: 768px) {
    .backToTop .text {
        padding-bottom: .125rem;
    }
}
@media (max-width: 1280px) {
    .main-part {
        padding: 0;
        margin: 1.1875rem 1.675rem 1.1875rem 1.1875rem;
    }
    li {
        margin-bottom: 2rem;
    }
}
</style>
<style scoped>
ul li:before {
    content: "·";
    margin-right: .5rem;
}
</style>